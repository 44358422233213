import { i18n } from '@/i18n';
import { isEmail, isValidPwd } from '@/utils/core';

export default function (form: any) {
    return {
        username: [
            {
                trigger: ['blur', 'change'],
                validator(rule: string, value: string, callback: any) {
                    if (form.err_message && form.err_type === 'username') {
                        callback(new Error(form.err_message));
                    }
                    callback();
                },
            },
            {
                required: true,
                trigger: ['change'],
                validator(rule: string, value: string, callback: any) {
                    if (!value) {
                        if (form.login_type === 'email') {
                            callback(new Error(i18n.global.t('75602') as string));
                        }
                    }

                    if (form.login_type === 'phone') {
                        const phone = value && value.split('-');

                        if (!phone[1]) {
                            callback(new Error(i18n.global.t('75603') as string));
                        }
                        //  else if (phone[1].length < 6 || phone[1].length > 17) {
                        //     callback(new Error('Enter a valid phone number'));
                        // }
                        else {
                            callback();
                        }
                    }
                    callback();
                },
            },
            {
                required: true,
                trigger: ['blur'],
                validator(rule: string, value: string, callback: any) {
                    if (form.login_type === 'email' && value && !isEmail(value)) {
                        callback(new Error(i18n.global.t('75609') as string));
                    }
                    callback();
                },
            },
        ],
        password: [
            {
                trigger: ['blur', 'change'],
                validator(rule: string, value: string, callback: any) {
                    if (form.err_message && form.err_type === 'password') {
                        callback(new Error(form.err_message));
                    }
                    callback();
                },
            },
            {
                required: true,
                trigger: ['change'],
                validator(rule: string, value: string, callback: any) {
                    if (!value) {
                        callback(new Error(i18n.global.t('75605') as string));
                    }
                    // else if (!isValidPwd(value)) {
                    //     callback(new Error(i18n.global.t('75610') as string));
                    // }
                    else {
                        callback();
                    }
                },
            },
        ],
        confirm_password: [
            {
                required: true,
                trigger: ['change'],
                validator(rule: string, value: string, callback: any) {
                    if (!value) {
                        callback(new Error(i18n.global.t('75607') as string));
                    } else {
                        callback();
                    }
                },
            },
            {
                required: true,
                trigger: ['blur'],
                validator(rule: string, value: string, callback: any) {
                    if (value && form.password !== value) {
                        callback(new Error(i18n.global.t('75608') as string));
                    } else {
                        callback();
                    }
                },
            },
        ],
        old_password: [
            {
                required: true,
                trigger: ['change'],
                validator(rule: string, value: string, callback: any) {
                    if (!value) {
                        callback(new Error(i18n.global.t('75633') as string));
                    } else {
                        callback();
                    }
                },
            },
            {
                required: true,
                trigger: ['blur'],
                validator(rule: string, value: string, callback: any) {
                    if (value && !isValidPwd(value)) {
                        callback(new Error(i18n.global.t('75610') as string));
                    } else {
                        callback();
                    }
                },
            },
            {
                trigger: ['blur', 'change'],
                validator(rule: string, value: string, callback: any) {
                    if (form.err_message && form.err_type === 'old_password') {
                        callback(new Error(form.err_message));
                    }
                    callback();
                },
            },
        ],
        new_password: [
            {
                required: true,
                trigger: ['change'],
                validator(rule: string, value: string, callback: any) {
                    if (!value) {
                        callback(new Error(i18n.global.t('75606') as string));
                    } else {
                        callback();
                    }
                },
            },
            {
                required: true,
                trigger: ['blur'],
                validator(rule: string, value: string, callback: any) {
                    if (value && !isValidPwd(value)) {
                        callback(new Error(i18n.global.t('75610') as string));
                    } else {
                        callback();
                    }
                },
            },
        ],
        confirm_new_password: [
            {
                required: true,
                trigger: ['change'],
                validator(rule: string, value: string, callback: any) {
                    if (!value) {
                        callback(new Error(i18n.global.t('75607') as string));
                    } else {
                        callback();
                    }
                },
            },
            {
                required: true,
                trigger: ['blur'],
                validator(rule: string, value: string, callback: any) {
                    if (!value || form.new_password !== value) {
                        callback(new Error(i18n.global.t('75608') as string));
                    } else {
                        callback();
                    }
                },
            },
        ],
    };
}
