
import { accountRules } from '@/common/js/form-rule/index'
import { URLS } from '@/common/js/constant/index'
import { reactive, toRefs, defineComponent, onBeforeMount, ref } from 'vue'
import KlookPageTitle from '@/components/page-title.vue'
import { post } from '@/plugins'
import { md5 } from '@/utils/core'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { FormInstance, message } from 'ant-design-vue'
import { useI18n } from 'vue-i18n'
import Cookie from 'js-cookie'
import { specialCode } from '@/plugins/axios'

export default defineComponent({
  components: {
    KlookPageTitle
  },

  setup() {
    const router = useRouter()
    const formRef = ref<FormInstance | any>()
    const store = useStore()
    const { t } = useI18n({})

    const state = reactive({
      formRules: {},
      form: {
        old_password: '',
        new_password: '',
        confirm_new_password: '',
        err_message: '',
        err_type: 'old_password'
      },
      isLoading: false
    })

    onBeforeMount(() => {
      state.formRules = accountRules.call(null, state.form)
    })

    const save = () => {
      formRef.value.validate().then(() => {
        state.isLoading = true
        post(URLS.changePassword, {
          new_password: md5(state.form.new_password),
          old_password: md5(state.form.old_password)
        })
          .then(() => {
            message.success(t('76173') as string)
            store.commit('user/cleanUserInfo')
            Cookie.remove('isLogin')
            router.push({ name: 'login' })
          })
          .catch((err) => {
            setErrMessage(err)
          })
          .finally(() => {
            state.isLoading = false
          })
      })
    }

    // 设置错误信息
    const setErrMessage = (res: any) => {
      if (specialCode[res.code]) {
        switch (specialCode[res.code]) {
          case '密码错误':
            state.form.err_message = res.message
            state.form.err_type = 'old_password'
            formRef.value.validateFields()
            break

          default:
            break
        }
        setTimeout(() => {
          state.form.err_message = ''
        }, 1000)
      }
    }

    return {
      ...toRefs(state),
      formRef,
      save
    }
  }
})
