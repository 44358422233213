import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_page_header = _resolveComponent("a-page-header")!

  return (_openBlock(), _createBlock(_component_a_page_header, {
    class: "klook-page-title",
    title: _ctx.title
  }, {
    extra: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["title"]))
}