import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a88e2f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-settings" }
const _hoisted_2 = { class: "page-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_klook_page_title = _resolveComponent("klook-page-title")!
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_klook_page_title, {
      title: _ctx.$t('76214')
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_form, {
        ref: "formRef",
        class: "setting-module setting-password",
        model: _ctx.form,
        rules: _ctx.formRules,
        layout: "vertical"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            label: _ctx.$t('75632'),
            name: "old_password",
            style: {"margin-bottom":"16px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input_password, {
                value: _ctx.form.old_password,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.old_password) = $event)),
                placeholder: _ctx.$t('75633'),
                autocomplete: ""
              }, null, 8, ["value", "placeholder"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_a_form_item, {
            label: _ctx.$t('75634'),
            name: "new_password",
            extra: _ctx.$t('75610'),
            style: {"margin-bottom":"16px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input_password, {
                value: _ctx.form.new_password,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.new_password) = $event)),
                placeholder: _ctx.$t('75606'),
                autocomplete: ""
              }, null, 8, ["value", "placeholder"])
            ]),
            _: 1
          }, 8, ["label", "extra"]),
          _createVNode(_component_a_form_item, {
            name: "confirm_new_password",
            style: {"margin-bottom":"16px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input_password, {
                value: _ctx.form.confirm_new_password,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.confirm_new_password) = $event)),
                placeholder: _ctx.$t('75607'),
                autocomplete: ""
              }, null, 8, ["value", "placeholder"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                type: "primary",
                loading: _ctx.isLoading,
                onClick: _ctx.save
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('75592')), 1)
                ]),
                _: 1
              }, 8, ["loading", "onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ])
  ]))
}